import { Component, Input } from '@angular/core';
import { SocialIcon } from './SocialIcon';
import { TranslatePipe } from "@ngx-translate/core";

@Component({
  selector: 'SocialIcons',
  imports: [SocialIcon, TranslatePipe],

  template: `
    <div class="flex gap-2.5">
      @if (links.linkedin) {
        <SocialIcon
            class="{{ iconClass }}"
            href="{{ links.linkedin }}"
            label="{{ 'SOCIAL_ICON_LABEL_LINKEDIN' | translate }}">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
          >
            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6zM2 9h4v12H2z"/>
            <circle cx="4" cy="4" r="2"/>
          </svg>
        </SocialIcon>

      } @else if (links.instagram) {
        <SocialIcon
            class="{{ iconClass }}"
            href="{{ links.instagram }}"
            label="{{ 'SOCIAL_ICON_LABEL_INSTAGRAM' | translate }}">
          <svg
              class="mt-px"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
          >
            <rect x="2" y="2" width="20" height="20" rx="5" ry="5"/>
            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37zM17.5 6.5h.01"/>
          </svg>
        </SocialIcon>

      } @else if (links.twitter) {
        <SocialIcon
            class="{{ iconClass }}"
            href="{{ links.twitter }}"
            label="{{ 'SOCIAL_ICON_LABEL_X' | translate }}">
          <svg
              class="mt-px"
              width="36"
              height="36"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-8 -8 40 40"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
          >
            <path
                d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
          </svg>
        </SocialIcon>
      }
    </div>
  `,
})
export class SocialIcons {
  @Input() iconClass = '';
  @Input() href = '';
  @Input() links: { linkedin?: string; instagram?: string; twitter?: string } =
      {
        linkedin: 'https://nl.linkedin.com/company/combidesk',
        instagram: 'https://www.instagram.com/combidesk/',
        twitter: 'https://x.com/combidesk',
      };
}
