import { Component, Input } from '@angular/core';

@Component({
  selector: 'MenuItemIcon',
  imports: [],
  template: `
    @if (type === 'dashboard') {
      <svg
          class="w-[17px] h-[17px]"
          viewBox="0 0 17 17"
          xmlns="http://www.w3.org/2000/svg"
      >
        <g
            transform="translate(1 1)"
            stroke="currentColor"
            stroke-width="1.66666667"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
          <rect width="15" height="15" rx="1.66666667"/>
          <path d="M0 5h15M5 15V5"/>
        </g>
      </svg>

    } @else if (type === 'history') {
      <svg
          class="w-[18px] h-[13px]"
          viewBox="0 0 18 13"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M5.57916667 1.41666667H16.4125m-10.83333333 5H16.4125M5.57916667 11.4166667H16.4125m-15-10.00000003h.00833333m-.00833333 5h.00833333M1.4125 11.4166667h.00833333"
            stroke="currentColor"
            stroke-width="1.66666667"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
      </svg>

    } @else if  (type === 'apps') {
      <svg
          class="w-[17px] h-[17px]"
          viewBox="0 0 17 17"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M1 1h5.83333333v5.83333333H1zm9.16666667 0H16v5.83333333h-5.83333333zm0 9.16666667H16V16h-5.83333333zm-9.16666667 0h5.83333333V16H1z"
            stroke="currentColor"
            stroke-width="1.66666667"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
      </svg>

    } @else if (type === 'settings') {
      <svg
          class="w-[21px] h-[21px]"
          viewBox="0 0 21 21"
          xmlns="http://www.w3.org/2000/svg"
      >
        <g
            transform="translate(1 1)"
            stroke="currentColor"
            stroke-width="1.6667"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
          <circle cx="9.1667" cy="9.1667" r="2.5"/>
          <path
              d="M15.3333 11.6667a1.375 1.375 0 0 0 .275 1.5166l.05.05a1.6667 1.6667 0 0 1 0 2.3584 1.6667 1.6667 0 0 1-2.3583 0l-.05-.05a1.375 1.375 0 0 0-1.5167-.275A1.375 1.375 0 0 0 10.9 16.525v.1417c0 .9204-.7462 1.6666-1.6667 1.6666-.9204 0-1.6666-.7462-1.6666-1.6666v-.075a1.375 1.375 0 0 0-.9-1.2584 1.375 1.375 0 0 0-1.5167.275l-.05.05a1.6667 1.6667 0 0 1-2.3583 0 1.6667 1.6667 0 0 1 0-2.3583l.05-.05a1.375 1.375 0 0 0 .275-1.5167A1.375 1.375 0 0 0 1.8083 10.9h-.1416C.7462 10.9 0 10.1538 0 9.2333c0-.9204.7462-1.6666 1.6667-1.6666h.075A1.375 1.375 0 0 0 3 6.6667 1.375 1.375 0 0 0 2.725 5.15l-.05-.05a1.6667 1.6667 0 0 1 0-2.3583 1.6667 1.6667 0 0 1 2.3583 0l.05.05a1.375 1.375 0 0 0 1.5167.275h.0667A1.375 1.375 0 0 0 7.5 1.8083v-.1416C7.5.7462 8.2462 0 9.1667 0c.9204 0 1.6666.7462 1.6666 1.6667v.075A1.375 1.375 0 0 0 11.6667 3a1.375 1.375 0 0 0 1.5166-.275l.05-.05a1.6667 1.6667 0 0 1 2.3584 0 1.6667 1.6667 0 0 1 0 2.3583l-.05.05a1.375 1.375 0 0 0-.275 1.5167v.0667A1.375 1.375 0 0 0 16.525 7.5h.1417c.9204 0 1.6666.7462 1.6666 1.6667 0 .9204-.7462 1.6666-1.6666 1.6666h-.075a1.375 1.375 0 0 0-1.2584.8334Z"
          />
        </g>
      </svg>

    } @else if (type === 'help') {
      <svg
          class="w-[19px] h-[19px]"
          viewBox="0 0 19 19"
          xmlns="http://www.w3.org/2000/svg"
      >
        <g
            transform="translate(1 1)"
            stroke="currentColor"
            stroke-width="1.6667"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
          <circle cx="8.3333" cy="8.3333" r="8.3333"/>
          <path
              d="M5.9083 5.8333c.4046-1.15 1.5795-1.8405 2.781-1.6344 1.2015.2061 2.0792 1.2487 2.0774 2.4678 0 1.6666-2.5 2.5-2.5 2.5M8.3333 12.5h.0084"
          />
        </g>
      </svg>

    } @else if (type === 'user') {
      <svg
          class="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
      >
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
        <circle cx="12" cy="7" r="4"/>
      </svg>

    } @else if (type === 'link') {
      <svg
          class="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
      >
        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"/>
        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"/>
      </svg>

    } @else if (type === 'sliders') {
      <svg
          class="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
      >
        <line x1="4" y1="21" x2="4" y2="14"/>
        <line x1="4" y1="10" x2="4" y2="3"/>
        <line x1="12" y1="21" x2="12" y2="12"/>
        <line x1="12" y1="8" x2="12" y2="3"/>
        <line x1="20" y1="21" x2="20" y2="16"/>
        <line x1="20" y1="12" x2="20" y2="3"/>
        <line x1="1" y1="14" x2="7" y2="14"/>
        <line x1="9" y1="8" x2="15" y2="8"/>
        <line x1="17" y1="16" x2="23" y2="16"/>
      </svg>

    } @else if (type === 'file') {
      <svg
          class="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
      >
        <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"/>
        <polyline points="13 2 13 9 20 9"/>
      </svg>

    } @else if (type === 'thumbs-up') {
      <svg
          class="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
      >
        <path
            d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"
        />
      </svg>

    } @else if (type === 'alert-circle') {
      <svg
          class="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
      >
        <circle cx="12" cy="12" r="10"/>
        <line x1="12" y1="8" x2="12" y2="12"/>
        <line x1="12" y1="16" x2="12.01" y2="16"/>
      </svg>
    }
  `,
})
export class MenuItemIcon {
  @Input() type = '';
}
