import { Injectable } from "@angular/core";
import { UserService } from "../user/user.service";
import { API, CombiPackage, FromAPI, FromAPIActionFilterStep, ToAPIActionConfigStep, User, UserAPI, UserCombiPackage } from "../../model";
import { MessageService } from "../../modules/shared/message/message.service";
import { ProgressBarService } from "../../modules/shared/progress-bar/progress-bar.service";
import { APIResult, BaseHttpService } from "../base-http.service";
import { ConfiguredFilterStep } from "../../pages/authenticated/combipackageconfig/dto/configured-filter-step.dto";
import { ConfiguredConfigStep } from "../../pages/authenticated/combipackageconfig/dto/configured-config-step.dto";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { UserCombiFilterStep } from "../../model/usercombistep";
import { logAnalyticsEvent } from "../../util/analytics.util";

export interface CombiPackageWrapper {
  userCombiPackage: UserCombiPackage;
}

export interface CombiPackageLoadUserWithPackageResult {
  loadUserResult: CombiPackageLoadUserResult;
  combiPackage: CombiPackageWrapper;
}

export interface CombiPackageLoadUserResult {
  user: User;
  userAPI: UserAPI;
  fromAPI: FromAPI;
  packageInternalKey: string;
}

export interface CombiPackageFinalizeResult {
  returnUrl: string;
  user: User;
}

@Injectable({
  providedIn: 'root'
})
export class CombiPackageService extends BaseHttpService {

  private combiPackage: CombiPackage;

  constructor(protected http: HttpClient,
              protected userService: UserService,
              protected translateService: TranslateService,
              protected progressBarService: ProgressBarService,
              protected messageService: MessageService) {
    super(http, userService, translateService, progressBarService, messageService);
  }

  getCombiPackageFromService(): CombiPackage {
    return this.combiPackage;
  }

  async getCombiPackages(apiName: string, apiInternalKey: string): Promise<CombiPackagesResult> {
    return (await this.get<CombiPackagesResult>(`unauthenticated/combipackages/${apiName}${apiInternalKey ? '/' + apiInternalKey : ''}`)).result;
  }

  async getRecommendedCombiPackagesForPackage(combiPackageInternalKey: string): Promise<Array<CombiPackage>> {
    return (await this.get<Array<CombiPackage>>(`unauthenticated/recommendedcombipackages/${combiPackageInternalKey}`)).result;
  }

  async getRecommendedCombiPackages(): Promise<Array<CombiPackage>> {
    return (await this.get<Array<CombiPackage>>("authenticated/recommendedcombipackages/list")).result;
  }

  async getCombiPackage(packageInternalKey: string): Promise<CombiPackage> {
    return (await this.get<CombiPackage>(`unauthenticated/combipackageinstall/${packageInternalKey}`)).result;
  }

  async getCombiPackageOAuthKeyByInternalKey(packageInternalKey: string, discriminator: string): Promise<string> {
    return (await this.get<string>(`unauthenticated/combipackageoauthkey/${packageInternalKey}/${discriminator}`)).result;
  }

  async getFromAPIActionFilterStepsForCombiPackage(combiPackageID: string, fromUserAPIID: string, toUserAPIID: string, userCombiPackageID: string, userCombiFilterSteps: Array<UserCombiFilterStep>): Promise<Array<FromAPIActionFilterStep>> {
    return (await this.post<Array<FromAPIActionFilterStep>>(`unauthenticated/combipackage/${combiPackageID}/filtersteps`, {fromUserAPIID, toUserAPIID, userCombiPackageID, userCombiFilterSteps})).result;
  }

  async getToAPIActionConfigStepsForCombiPackage(combiPackageId: string, fromUserAPIID: string, toUserAPIID: string, configuredFilterSteps: Array<ConfiguredFilterStep>): Promise<Array<ToAPIActionConfigStep>> {
    return (await this.post<Array<ToAPIActionConfigStep>>(`unauthenticated/combipackage/${combiPackageId}/configsteps`, {fromUserAPIID, toUserAPIID, configuredFilterSteps})).result;
  }

  async testToAPIActionConfigStep(testThisToAPIActionConfigStepId: number, testThisToAPIActionConfigStepValue: string, allConfigSteps: Array<ConfiguredConfigStep>, fromUserAPI: UserAPI, toUserAPI: UserAPI): Promise<Array<ToAPIActionConfigStep>> {
    return (await this.post<Array<ToAPIActionConfigStep>>(`authenticated/testconfigstep`, {testAPIActionConfigStep: {testThisToAPIActionConfigStepId, testThisToAPIActionConfigStepValue, allConfigSteps, fromUserAPI, toUserAPI}})).result;
  }

  async getToAPIActionConfigStepForCombiPackageWithParentValueConfigured(configstepid: number, fromapiactionid: number, usercombiid: number, fromUserAPIID: string, toUserAPIID: string, filterSteps: Array<ConfiguredFilterStep>, configSteps: Array<ConfiguredConfigStep>, isEdit: boolean, parentValue: string): Promise<ToAPIActionConfigStep> {
    return (await this.post<ToAPIActionConfigStep>(`unauthenticated/toapiactionconfigstepwithparentvalueconfigured/${configstepid}`, {fromapiactionid, usercombiid, fromUserAPIID, toUserAPIID, filterSteps, configSteps, isEdit, parentValue})).result;
  }

  async getToAPIActionConfigStepForCombiPackageForParent(childConfigStepIds: Array<number>, fromAPIActionID: number, userCombiID: number, fromUserAPIID: string, toUserAPIID: string, filterSteps: Array<ConfiguredFilterStep>, configSteps: Array<ConfiguredConfigStep>, isEdit: boolean, parentValue: string): Promise<Array<ToAPIActionConfigStep>> {
    return (await this.post<Array<ToAPIActionConfigStep>>(`unauthenticated/toapiactionconfigstepsforparentconfigured`, {fromAPIActionID, childConfigStepIds, userCombiID, fromUserAPIID, toUserAPIID, filterSteps, configSteps, isEdit, parentValue})).result;
  }

  async getFromAPIActionChildFilterSteps(childids: Array<number>, fromUserAPIID: string, toUserAPIID: string, usercombiid: number, configuredFilterSteps: Array<ConfiguredFilterStep>, parentValue: string): Promise<APIResult<Array<FromAPIActionFilterStep>>> {
    return await this.post<Array<FromAPIActionFilterStep>>(`unauthenticated/fromapiactionchildfiltersteps`, {childids, fromUserAPIID, toUserAPIID, usercombiid, configuredFilterSteps, parentValue});
  }

  async getFromAPIActionFilterStepUpsellPrices(id: number, fromUserAPIID: string, toUserAPIID: string, usercombiid: number, configuredFilterSteps: Array<ConfiguredFilterStep>, parentValue: string): Promise<APIResult<FromAPIActionFilterStep>> {
    return await this.post<FromAPIActionFilterStep>(`unauthenticated/fromapiactionfilterstepupsellprices`, {id, fromUserAPIID, toUserAPIID, usercombiid, configuredFilterSteps, parentValue});
  }

  async loadUser(packagename: string, packageparams: string, language?: string): Promise<CombiPackageLoadUserResult> {
    return (await this.post<CombiPackageLoadUserResult>(`unauthenticated/combipackage/loaduser`, {packagerequest: {packagename, packageparams, language}}))?.result;
  }

  async loadUserWithPackage(packagename: string, packageparams: string): Promise<CombiPackageLoadUserWithPackageResult> {
    return (await this.post<CombiPackageLoadUserWithPackageResult>(`unauthenticated/combipackage/loaduserwithpackage`, {packagerequest: {packagename, packageparams}}))?.result;
  }

  async save(packagename: string, packageparams: string, usercombipackageid?: string): Promise<CombiPackage> {
    this.combiPackage = (await this.post<CombiPackage>(`unauthenticated/combipackage/save`, {packagerequest: {packagename, packageparams, usercombipackageid}}))?.result;
    return this.combiPackage;
  }

  async saveInstallationProgress(packagename: string, packageparams: string, fromUserAPIID: string, toUserAPIID: string, filterSteps: Array<ConfiguredFilterStep>, configSteps: Array<ConfiguredConfigStep>, restrictEditing: boolean): Promise<CombiPackageFinalizeResult> {
    const result = (await this.post<CombiPackageFinalizeResult>(`unauthenticated/combipackage/saveinstallationprogress`, {packagerequest: {packagename, packageparams}, saveUserCombi: {fromUserAPIID, toUserAPIID, filterSteps, configSteps, restrictEditing}})).result;
    if (result) {
      logAnalyticsEvent("installation_progress_saved", {fromUserAPIID, toUserAPIID});
    }
    return result;
  }

  async finalize(email: string, newsletter: boolean, packagename: string, packageparams: string, fromUserAPIID: string, toUserAPIID: string, filterSteps: Array<ConfiguredFilterStep>, configSteps: Array<ConfiguredConfigStep>, restrictEditing: boolean): Promise<CombiPackageFinalizeResult> {
    const result = (await this.post<CombiPackageFinalizeResult>(`unauthenticated/combipackage/finalizeinstall`, {email, newsletter, packagerequest: {packagename, packageparams}, saveUserCombi: {fromUserAPIID, toUserAPIID, filterSteps, configSteps, restrictEditing}})).result;
    if (result) {
      logAnalyticsEvent("installation_finalized", {fromUserAPIID, toUserAPIID});
    }
    return result;
  }

  async update(packagename: string, packageparams: string, usercombipackageid: string, fromUserAPIID: string, toUserAPIID: string, filterSteps: Array<ConfiguredFilterStep>, configSteps: Array<ConfiguredConfigStep>, origin: string, restrictEditing: boolean): Promise<string> {
    return (await this.post<string>(`unauthenticated/combipackage/updateinstall`, {packagerequest: {packagename, packageparams, usercombipackageid}, saveUserCombi: {fromUserAPIID, toUserAPIID, filterSteps, configSteps, origin, restrictEditing}})).result;
  }

  async cancel(packagename: string, packageparams: string, usercombipackageid: string, origin: string, userCombiID: number): Promise<string> {
    return (await this.post<string>(`unauthenticated/combipackage/cancelupdate`, {packagerequest: {packagename, packageparams, usercombipackageid}, userCombiID, saveUserCombi: {origin}})).result;
  }

  async combiPackageInstalled(packageinternalkey: string): Promise<boolean> {
    return (await this.get<boolean>(`authenticated/combipackage/installed/${packageinternalkey}`)).result;
  }

  async combiPackageInstallStarted(packageinternalkey: string): Promise<boolean> {
    return (await this.post<boolean>(`unauthenticated/combipackageinstallstarted/${packageinternalkey}`)).result;
  }
}

export interface CombiPackagesResult {
  apiName: string;
  apiInternalKey: string;
  api: API;
  combiPackages: Array<CombiPackage>;
}
