import { Button } from './Button';
import { Component } from '@angular/core';
import { TranslateDirective, TranslatePipe } from "@ngx-translate/core";
import { showIntercom } from "../util/intercom.util";
import { ContentImage } from "./ContentImage";

@Component({
  selector: 'CustomerService',
  imports: [TranslatePipe, TranslateDirective, Button, ContentImage],
  template: `
    <ContentImage imageAlt="Klantenservice" img="team">
      <h2 class="h1" translate="CUSTOMERSERVICE_TITLE"></h2>
      <p translate="CUSTOMERSERVICE_BODY"></p>
      <div class="flex flex-wrap gap-3 md:gap-4">
        <Button id="chat" color="blue" (click)="triggerIntercom()">
          {{ 'CUSTOMERSERVICE_BUTTON_CHAT' | translate }}
        </Button>
        <Button color="blue" [href]="'SUPERFOOTER_LINK_INSTALLATION_URL' | translate" [outline]="true">
          {{ 'CUSTOMERSERVICE_BUTTON_HELP' | translate }}
        </Button>
      </div>
    </ContentImage>
  `,
})
export class CustomerService {
  triggerIntercom() {
    showIntercom();
  }
}
