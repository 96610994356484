import { Component, Input } from '@angular/core';
import { TranslatePipe } from "@ngx-translate/core";
import { Button } from "./Button";

@Component({
  selector: 'HeroApps',
  imports: [TranslatePipe, Button],
  template: `
    <div
        class="
            mb-12 flex-none z-10 relative
            w-[280px] h-[320px] xs:w-[340px] sm:w-[440px] sm:h-[400px]
            md:mb-18
            lg:ml-15 lg:-mt-6 lg:-mb-12
            xl:mr-15
          "
    >
      @if (secondAppIcon) {
        <div class="
          bg-white p-10 shadow-2xl absolute right-0 top-0 center flex-col
          w-[180px] h-[200px] sm:w-[240px] sm:h-[280px]
          rounded-xl sm:rounded-3xl"
        >
          <img
              class="w-20 h-20 sm:w-30 sm:h-30 mb-4"
              src="assets/images/apps/{{ secondAppIcon }}.svg"
          />
          <h4 class="text-base text-center sm:text-lg">
            {{ secondAppName }}
          </h4>
          @if (secondAppRegistrationUrl) {
            <Button
                class="mt-1 "
                color="blue"
                size="xsmall"
                [outline]="true"
                (click)="openReferralUrl(secondAppRegistrationUrl)">
              {{ 'HEROAPPS_OPEN_ACCOUNT' | translate }}
            </Button>
          }
        </div>
      }

      @if (!secondAppIcon) {
        <div class="
          bg-blue-200 p-6 shadow-2xl absolute right-0 top-0 center flex-col
          w-[180px] h-[200px] sm:w-[240px] sm:h-[280px]
          rounded-xl sm:rounded-3xl"
        >
          <div class="
            bg-blue-500 rounded-full center shadow-xl shadow-blue-500/50
            w-18 h-18 sm:w-24 sm:h-24"
          >
            <svg
                class="text-white w-2/5 h-2/5"
                viewBox="0 0 30 30"
                xmlns="http://www.w3.org/2000/svg"
            >
              <g
                  stroke="currentColor"
                  stroke-width="4"
                  fill="none"
                  fill-rule="evenodd"
              >
                <path d="M0 15.5h30M15.5 0v30"/>
              </g>
            </svg>
          </div>
        </div>
      }

      <div class="
          bg-white p-10 shadow-2xl absolute left-0 bottom-0 center flex-col
          w-[180px] h-[200px] sm:w-[240px] sm:h-[280px]
          rounded-xl sm:rounded-3xl"
      >
        <img
            class="w-20 h-20 sm:w-30 sm:h-30 mb-4"
            src="assets/images/apps/{{ appIcon }}.svg"
        />
        <h4 class="text-base text-center sm:text-lg">{{ appName }}</h4>

        @if (appRegistrationUrl) {
          <Button
              class="mt-1"
              color="blue"
              size="xsmall"
              [outline]="true"
              (click)="openReferralUrl(appRegistrationUrl)">
            {{ 'HEROAPPS_OPEN_ACCOUNT' | translate }}
          </Button>
        }
      </div>

      <svg
          class="xs:hidden absolute left-10 top-15"
          height="60"
          viewBox="0 0 60 60"
          width="60"
          xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="a">
          <path d="m60 0v60h-60v-60z"/>
        </clipPath>
        <g clip-path="url(#a)" fill="none" stroke="#fff" stroke-width="2">
          <path
              d="m30 0h130c16.568542 0 30 13.4314575 30 30v140c0 16.568542-13.431458 30-30 30h-130c-16.5685425 0-30-13.431458-30-30v-140c0-16.5685425 13.4314575-30 30-30z"
              stroke-dasharray="5 5"
              stroke-linejoin="round"
              transform="matrix(0 1 -1 0 207 7)"
          />
          <g stroke-miterlimit="10">
            <path d="m.5 0v14" transform="translate(6.5 46)"/>
            <path d="m0 0 6 8 6-8" transform="matrix(0 -1 1 0 52 13)"/>
            <path d="m.5 0v14" transform="matrix(0 -1 1 0 46 7.5)"/>
            <path d="m0 0 6 8 6-8" transform="translate(1 52)"/>
          </g>
        </g>
      </svg>

      <svg
          height="60"
          viewBox="0 0 120 60"
          class="hidden xs:block absolute left-10 sm:left-20 top-15"
          width="120"
          xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="a">
          <path d="m120 0v60h-120v-60z"/>
        </clipPath>
        <g clip-path="url(#a)" fill="none" stroke="#fff" stroke-width="2">
          <path
              d="m30 0h130c16.568542 0 30 13.4314575 30 30v140c0 16.568542-13.431458 30-30 30h-130c-16.5685425 0-30-13.431458-30-30v-140c0-16.5685425 13.4314575-30 30-30z"
              stroke-dasharray="5 5"
              stroke-linejoin="round"
              transform="matrix(0 1 -1 0 207 7)"
          />
          <g stroke-miterlimit="10">
            <path d="m.5 0v14" transform="translate(6.5 46)"/>
            <path d="m0 0 6 8 6-8" transform="matrix(0 -1 1 0 112 13)"/>
            <path d="m.5 0v14" transform="matrix(0 -1 1 0 106 7.5)"/>
            <path d="m0 0 6 8 6-8" transform="translate(1 52)"/>
          </g>
        </g>
      </svg>

      <svg
          class="xs:hidden absolute right-10 bottom-15"
          height="60"
          viewBox="0 0 60 60"
          width="60"
          xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="a">
          <path d="m60 0v60h-60v-60z"/>
        </clipPath>
        <g clip-path="url(#a)" fill="none" stroke="#fff" stroke-width="2">
          <path
              d="m30 0h130c16.568542 0 30 13.4314575 30 30v140c0 16.568542-13.431458 30-30 30h-130c-16.5685425 0-30-13.431458-30-30v-140c0-16.5685425 13.4314575-30 30-30z"
              stroke-dasharray="5 5"
              stroke-linejoin="round"
              transform="matrix(0 -1 1 0 -147 53)"
          />
          <g stroke-miterlimit="10">
            <path d="m.5 0v14" transform="matrix(-1 0 0 -1 53.5 14)"/>
            <path d="m0 0 6 8 6-8" transform="matrix(0 1 -1 0 8 47)"/>
            <path d="m.5 0v14" transform="matrix(0 1 -1 0 14 52.5)"/>
            <path d="m0 0 6 8 6-8" transform="matrix(-1 0 0 -1 59 8)"/>
          </g>
        </g>
      </svg>

      <svg
          class="hidden xs:block absolute right-10 sm:right-20 bottom-15"
          height="60"
          viewBox="0 0 120 60"
          width="120"
          xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="a">
          <path d="m120 0v60h-120v-60z"/>
        </clipPath>
        <g clip-path="url(#a)" fill="none" stroke="#fff" stroke-width="2">
          <path
              d="m30 0h130c16.568542 0 30 13.4314575 30 30v140c0 16.568542-13.431458 30-30 30h-130c-16.5685425 0-30-13.431458-30-30v-140c0-16.5685425 13.4314575-30 30-30z"
              stroke-dasharray="5 5"
              stroke-linejoin="round"
              transform="matrix(0 -1 1 0 -87 53)"
          />
          <g stroke-miterlimit="10">
            <path d="m.5 0v14" transform="matrix(-1 0 0 -1 113.5 14)"/>
            <path d="m0 0 6 8 6-8" transform="matrix(0 1 -1 0 8 47)"/>
            <path d="m.5 0v14" transform="matrix(0 1 -1 0 14 52.5)"/>
            <path d="m0 0 6 8 6-8" transform="matrix(-1 0 0 -1 119 8)"/>
          </g>
        </g>
      </svg>
    </div>
  `,
})
export class HeroApps {
  @Input() appIcon: string;
  @Input() appName: string;
  @Input() appRegistrationUrl: string;

  @Input() secondAppIcon: string;
  @Input() secondAppName: string;
  @Input() secondAppRegistrationUrl: string;

  openReferralUrl(url: string) {
    window.open(url, "_blank");
  }
}
