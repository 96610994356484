import { Component, output, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "../message/message.service";
import { isEmailValid } from "../../../util/email.util";
import { firstValueFrom } from "rxjs";

@Component({
  standalone: false,
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent {
  @Input({required: true}) email: string;
  @Input() showLabel = true;
  emailChange = output<string>();
  @Input() label: string;
  @Input() order = false;
  @Input() standalone = false; // not part of a form
  @Input() required = false;

  constructor(private translate: TranslateService,
              private messageService: MessageService) {
  }

  setEmail() {
    this.emailChange.emit(this.email);
  }

  async validateEmail($event: FocusEvent) {
    if (this.required && !isEmailValid(this.email) && !(<Element>($event?.relatedTarget)).classList.contains('close-modal')) {
      this.messageService.showErrorMessage(await firstValueFrom(this.translate.get("COMPONENT_EMAIL_INVALID")));
    }
  }

  isValidEmail(): boolean {
    return isEmailValid(this.email);
  }
}
