import { Component } from '@angular/core';

@Component({
  selector: 'AppSuggestion',
  template: `
    <div
      class="aspect-square rounded-3xl bg-blue-200 mb-2.5 flex items-center justify-center overflow-hidden"
    >
      <div
        class="w-[45%] h-[45%] bg-blue-500 rounded-full flex items-center justify-center transform group-hover:scale-[300%] transition-transform duration-200 ease-out"
      ></div>

      <svg
        class="absolute text-white"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          stroke="currentColor"
          stroke-width="4"
          fill="none"
          fill-rule="evenodd"
        >
          <path d="M0 15.5h30M15.5 0v30" />
        </g>
      </svg>
    </div>
  `,
})
export class AppSuggestion {}
