import { Component, Input } from '@angular/core';

@Component({
  selector: 'Star',
  imports: [],
  template: `
    <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fill-rule="evenodd">
        <path
            [attr.fill]="half || empty ? '' : '#F8B62E'"
            stroke="#F8B62E"
            stroke-width="1.667"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m12.012 6.266 5.812.85-4.206 4.096.993 5.788-5.199-2.734L4.213 17l.993-5.788L1 7.116l5.813-.85L9.412 1z"
        />
        @if (!empty) {
          <path
              fill="#F8B62E"
              d="M9.412 14.266 4.213 17l.993-5.788L1 7.116l5.813-.85L9.412 1z"
          />
        }
      </g>
    </svg>
  `,
})
export class Star {
  @Input() empty = false;
  @Input() half = false;
}
