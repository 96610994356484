import { Component, Input } from '@angular/core';

@Component({
  selector: 'SocialIcon',
  template: `
    <a
        [attr.aria-label]="label"
        class="
        my-button
        border-2
        rounded-full
        w-12
        h-12
        flex
        items-center
        justify-center
        transition-colors
        {{ class }}
      "
        [href]="href"
        target="_blank"
        rel="noopener noreferrer nofollow"
    >
      <ng-content></ng-content>
    </a>
  `,
})
export class SocialIcon {
  @Input() class = '';
  @Input() href = '';
  @Input() label = '';
}
