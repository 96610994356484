import { Component, Input } from '@angular/core';
import { HeroApps } from './HeroApps';
import { Wrap } from './Wrap';

@Component({
  selector: 'Hero',
  imports: [HeroApps, Wrap],
  template: `
    <div class="pt-5 md:pt-10 lg:pt-10 lg:pb-20 xl:pt-15 xl:pb-24 {{ class }}">
      <Wrap
          class="flex flex-col lg:flex-row justify-center items-center w-full"
      >
        <div class="mb-10 md:mb-15 lg:mb-0 text-center lg:text-left">
          <ng-content></ng-content>
        </div>

        @if (!appIcon) {
          <img
              [alt]="illustration.alt || alt"
              src="assets/images/{{ illustration.name }}.svg"
              class="w-full max-w-[420px] lg:w-1/2 lg:max-w-[540px] flex-none lg:ml-15 -mb-5 lg:mb-0 xl:-mb-36 z-10 {{
            illustration.noBreakOut ? 'xl:-mb-5' : '-mb-5 xl:-mb-36'
          }}"
              [height]="illustration.height"
              [width]="illustration.width"
          />
        }

        @if (appIcon) {
          <HeroApps
              [appName]="appName"
              [appIcon]="appIcon"
              [appRegistrationUrl]="appRegistrationUrl"
              [secondAppName]="secondAppName"
              [secondAppIcon]="secondAppIcon"
              [secondAppRegistrationUrl]="secondAppRegistrationUrl"
          ></HeroApps>
        }
      </Wrap>
    </div>
  `,
})
export class Hero {
  @Input() alt? = "";
  @Input() class? = '';

  @Input() illustration: {
    name: string;
    alt?: string;
    noBreakOut?: boolean;
    height: number;
    width: number;
  } = {
    name: "hero",
    height: 431,
    width: 540,
  };

  @Input() appIcon: string;
  @Input() appName: string;
  @Input() appRegistrationUrl: string;

  @Input() secondAppIcon: string;
  @Input() secondAppName: string;
  @Input() secondAppRegistrationUrl: string;
}
