import { Component, OnInit } from "@angular/core";
import { showIntercom } from "../../util/intercom.util";
import { ActivatedRoute } from "@angular/router";

@Component({
  standalone: false,
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  constructor(private route: ActivatedRoute) {
  }

  async ngOnInit() {
    if (this.route.snapshot.routeConfig.path === "chat") {
      setTimeout(() => {
        showIntercom();
      }, 500);
    }
  }
}
