import { Component, Input } from '@angular/core';

import { Wrap } from './Wrap';

@Component({
  selector: 'Intro',
  imports: [Wrap],
  template: `
    <section class="my-section">
      <Wrap
        class="flex flex-col lg:flex-row justify-center items-center w-full"
      >
        <div class="mb-10 md:mb-15 lg:mb-0">
          <ng-content></ng-content>
        </div>

        <img
          [alt]="illustration.alt || alt"
          src="assets/images/{{ illustration.name }}.svg"
          class="flex-none lg:ml-30"
          [height]="illustration.height"
          [width]="illustration.width"
        />
      </Wrap>
    </section>
  `,
})
export class Intro {
  @Input() alt = "";
  @Input() illustration: {
    name: string;
    alt?: string;
    height: number;
    width: number;
  } = {
    name: "intro",
    height: 435,
    width: 420,
  };
}
