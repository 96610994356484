import { Component } from '@angular/core';
import { Wrap } from './Wrap';

@Component({
  selector: 'HeaderTitle',
  imports: [Wrap],
  template: `
    <div class="pt-5 pb-10 md:pt-10 md:pb-15 lg:pt-15 lg:pb-20">
      <Wrap>
        <h1 class="text-white">
          <ng-content></ng-content>
        </h1>
      </Wrap>
    </div>
  `,
})
export class HeaderTitle {}
