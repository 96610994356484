import { Component, OnInit } from "@angular/core";
import { LocalStorageKey } from "../../local-storage/local-storage-keys";
import { ActivatedRoute, Router } from "@angular/router";
import { OAuthService } from "../../services/oauth/oauth.service";
import { LocalStorageService } from "../../local-storage/local-storage.service";
import { UserCombiPackageService } from "../../services/usercombipackage/usercombipackage.service";
import { HttpUrlEncodingCodec } from "@angular/common/http";
import { OAuth } from "../../model";
import { redirect } from "../../util/redirect.util";
import { NEWUSERAPI_QUERYPARAM_NAME, OAUTHERROR_QUERYPARAM_NAME } from "../../modules/userapi-modal/oauth/oauth.util";

@Component({
  standalone: false,
  selector: 'app-oauth-result',
  templateUrl: './oauth-result.component.html'
})
export class OAuthResultComponent implements OnInit {

  showSpinner = false;

  constructor(private localStorageService: LocalStorageService,
              private oAuthService: OAuthService,
              private userCombiPackageService: UserCombiPackageService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    const queryParams = this.route.snapshot.queryParams;

    let oauthFlowCompleted = false;
    this.localStorageService.remove(LocalStorageKey.OAUTHFLOW_COMPLETED);

    let redirectAfterOAuth = this.localStorageService.get<string>(LocalStorageKey.REDIRECTTO_AFTER_OAUTHRESULT);
    if (queryParams["redirectToDomainAfterCompleteOAuthFlow"] !== undefined) {
      redirectAfterOAuth = queryParams["redirectToDomainAfterCompleteOAuthFlow"];
      oauthFlowCompleted = true;
    }

    // the url _may_ be encoded (fi. in the bizcuit-twinfield v2 flow in Safari), or in case this was the redirectToDomainAfterCompleteOAuthFlow param
    if (redirectAfterOAuth?.startsWith("%2F")) {
      redirectAfterOAuth = new HttpUrlEncodingCodec().decodeValue(redirectAfterOAuth);
    }

    this.showSpinner = true;

    const oAuthRequestResult = this.localStorageService.get<OAuth>(LocalStorageKey.OAUTHRESULT) || <OAuth>{};

    let oauthError;
    if (queryParams["oauth_verifier"] !== undefined) {
      oAuthRequestResult.secret = queryParams["oauth_verifier"];
    } else if (queryParams["code"] !== undefined) {
      oAuthRequestResult.secret = queryParams["code"];
    } else if (queryParams["redirectToDomainAfterCompleteOAuthFlow"] !== undefined && queryParams["redirectToDomainAfterCompleteOAuthFlow"].indexOf("code=") > -1) { // this seems to be an encoding bug in Datev, where the 'code' param is part of the 'redirectToDomainAfterCompleteOAuthFlow' param
      oAuthRequestResult.secret = queryParams["redirectToDomainAfterCompleteOAuthFlow"].substring(queryParams["redirectToDomainAfterCompleteOAuthFlow"].indexOf("code=") + "code=".length);
    } else if (queryParams["error"] !== undefined) {
      oauthError = queryParams["error"];
    }
    oAuthRequestResult.state = queryParams["state"];

    oAuthRequestResult.customParams = queryParams;

    const userApi = await this.oAuthService.snoozeMessages<OAuthService>().result(oAuthRequestResult);

    const hasRedirectAfterOAuth = redirectAfterOAuth && redirectAfterOAuth !== "null";
    if (!hasRedirectAfterOAuth) {
      // COMBI-2193, apparently, redirectAfterOAuth is sometimes null
      console.error(`Unexpected value for redirectAfterOAuth: ${redirectAfterOAuth}, with userApi: ${userApi} and oAuthRequestResult: ${JSON.stringify(oAuthRequestResult)}`);
    }

    if (userApi) {
      userApi.configuredThisSession = true;
      this.userCombiPackageService.setUserAPI(userApi);

      this.localStorageService.remove(LocalStorageKey.OAUTHRESULT);
      this.localStorageService.remove(LocalStorageKey.REDIRECTTO_AFTER_OAUTHRESULT);

      if (redirectAfterOAuth === "close") {
        window.opener.location.reload();
        window.close();
      } else if (redirectAfterOAuth === "closeWithoutReload") {
        window.close();
      } else if (hasRedirectAfterOAuth) {
        if (!oauthFlowCompleted) {
          redirectAfterOAuth += `&${OAUTHERROR_QUERYPARAM_NAME}=${!oauthError ? "" : oauthError}`;
        } else {
          this.localStorageService.set(LocalStorageKey.OAUTHFLOW_COMPLETED, "true");
        }
        redirect(this.router, redirectAfterOAuth + (redirectAfterOAuth?.indexOf("?") > -1 ? "&" : "?") + `${NEWUSERAPI_QUERYPARAM_NAME}=${userApi.id}`);
      }

    } else {
      if (redirectAfterOAuth === "close") {
        window.opener.location.reload();
        window.close();
      } else if (hasRedirectAfterOAuth) {
        if (!oauthFlowCompleted) {
          redirectAfterOAuth += `&${OAUTHERROR_QUERYPARAM_NAME}=${oauthError}`;
        }
        redirect(this.router, redirectAfterOAuth);
      }
    }
  }
}
