import { Component, Input } from '@angular/core';
import { Stars } from './Stars';

@Component({
  selector: 'CustomerReview',
  imports: [Stars],
  template: `
    <div
        class="
        bg-white
        flex-none
        shadow-soft
        rounded-3xl
        px-5 py-7 md:p-[30px]
        text-xs
        flex flex-col
        md:text-base
        max-w-full
        {{ fixedSize ? 'w-[240px] md:w-[300px]' : 'w-full h-full' }}
      "
    >
      <em class="mb-4 leading-relaxed">&ldquo;{{ content }}&rdquo;</em>
      <div class="mb-4 mt-auto">
        <Stars [rating]="rating"></Stars>
      </div>
      <div>
        <a [href]="url" target="_blank" class="font-bold !text-black !no-underline hover:!underline suppress-icon">{{ name }}</a>
        <div class="text-[10px]">{{ published }}</div>
      </div>
    </div>
  `
})
export class CustomerReview {
  @Input() name: string = '';
  @Input() published: string = '';
  @Input() rating: number = 0;
  @Input() content: string = '';
  @Input() url: string = '';
  @Input() fixedSize: boolean = false;
}
