import { Component, Input, output } from '@angular/core';
import { Button } from './Button';
import { Wrap } from './Wrap';
import { TranslateDirective, TranslatePipe } from "@ngx-translate/core";
import { Buttons } from "./Buttons";

@Component({
  selector: 'Cta',
  imports: [TranslatePipe, TranslateDirective, Button, Buttons, Wrap],
  template: `
    <div
        class="bg-gradient-to-br from-blue-50 to-blue-100 pt-10 md:pt-15 lg:py-15"
    >
      <Wrap
          class="flex flex-col lg:flex-row justify-center items-center w-full"
      >
        @if (type === 'try') {
          <div class="mb-10 md:mb-15 lg:mb-0">
            <h2 translate="CTA_TITLE"></h2>
            <p translate="CTA_BODY"></p>

            <Buttons>
              <Button href="/login?mode=REGISTER" color="red">
                {{ 'CTA_BUTTON_TRIAL' | translate }}
              </Button>
              <!--Button href="#informatie-aanvragen" [outline]="true">
                {{ 'CTA_BUTTON_INFO' | translate }}
              </Button-->
            </Buttons>
          </div>

        } @else if (type === 'support') {
          <div class="mb-10 md:mb-15 lg:mb-0">
            <h2 translate="CTA_TITLE"></h2>
            <p translate="CTA_BODY"></p>

            <Buttons class="flex flex-wrap gap-3 md:gap-4 mt-8">
              <Button href="/login?mode=REGISTER" color="red">
                {{ 'CTA_BUTTON_TRIAL' | translate }}
              </Button>
              <!--Button href="#informatie-aanvragen" [outline]="true">
                {{ 'CTA_BUTTON_INFO' | translate }}
              </Button-->
            </Buttons>
          </div>

        } @else if (type === 'software-partner') {
          <div class="mb-10 md:mb-15 lg:mb-0">
            <h2 translate="CTA_SOFTWARE_PARTNER_TITLE"></h2>
            <p translate="CTA_SOFTWARE_PARTNER_BODY"></p>

            <Buttons class="flex flex-wrap gap-3 md:gap-4 mt-8">
              <Button (click)="buttonClick()" color="red"
                      [shadow]="false">{{ 'CTA_SOFTWARE_PARTNER_BUTTON_BECOME' | translate }}
              </Button>
            </Buttons>
          </div>

        } @else if (type === 'advisor') {
          <div class="mb-10 md:mb-15 lg:mb-0">
            <h2 translate="CTA_ADVISOR_TITLE"></h2>
            <p translate="CTA_ADVISOR_BODY"></p>

            <Buttons class="flex flex-wrap gap-3 md:gap-4 mt-8">
              <Button (click)="buttonClick()" color="red" [shadow]="false">{{ 'CTA_ADVISOR_BUTTON' | translate }}
              </Button>
            </Buttons>
          </div>
        }

        <img
            alt="Combidesk"
            src="assets/images/{{ type }}.svg"
            class="lg:ml-30 xl:mr-30 lg:-mb-15"
            [height]="type === 'try' ? 368 : 354"
            [width]="type === 'try' ? 375 : 440"
        />
      </Wrap>
    </div>
  `,
})
export class Cta {
  @Input() type: 'support' | 'try' | 'software-partner' | 'advisor' = 'try';
  buttonClicked = output<void>();

  async buttonClick() {
    this.buttonClicked.emit();
  }
}
