import { Component, OnInit } from "@angular/core";
import { LocalStorageKey } from "../../local-storage/local-storage-keys";
import { ActivatedRoute, Router } from "@angular/router";
import { OAuthService } from "../../services/oauth/oauth.service";
import { LocalStorageService } from "../../local-storage/local-storage.service";
import { OAuth } from "../../model";
import { CombiPackageService } from "../../services/combipackage/combipackage.service";
import { UserService } from "../../services/user/user.service";
import { ShopifyUtil } from "../../util/shopify.util";
import createApp from "@shopify/app-bridge";

@Component({
  standalone: false,
  selector: 'app-shopify-oauth-result',
  template: ''
})
export class ShopifyOAuthResultComponent implements OnInit {

  constructor(private localStorageService: LocalStorageService,
              private combiPackageService: CombiPackageService,
              private userService: UserService,
              private oAuthService: OAuthService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    const packageInternalKey = this.route.snapshot.params["package"];
    const queryParams = this.route.snapshot.queryParams;
    const apiKey = await this.combiPackageService.getCombiPackageOAuthKeyByInternalKey(packageInternalKey, queryParams["shop"]);

    const combiPackageLoadUserResult = await this.userService.loadUserByAPIOAuthKeyAndUrlService(apiKey, queryParams["shop"]);

    let redirectAfterOAuth = this.localStorageService.get<string>(LocalStorageKey.REDIRECTTO_AFTER_OAUTHRESULT);
    let oAuthRequestResult = this.localStorageService.get<OAuth>(LocalStorageKey.OAUTHRESULT);

    if (!oAuthRequestResult) {
      oAuthRequestResult = <OAuth>{
        packageName: packageInternalKey,
        url: queryParams["shop"],
        externalApiId: combiPackageLoadUserResult.userAPI?.externalApiId
      };
    }

    oAuthRequestResult.secret = queryParams["code"];
    oAuthRequestResult.customParams = queryParams;

    if (this.userService.getUser() && !this.localStorageService.get(LocalStorageKey.CURRENT_USERAPI)) {
      const userApi = await this.oAuthService.result(oAuthRequestResult);
      if (userApi) {
        this.localStorageService.set(LocalStorageKey.CURRENT_USERAPI, userApi);
      }
    }

    this.localStorageService.remove(LocalStorageKey.OAUTHRESULT);
    this.localStorageService.remove(LocalStorageKey.REDIRECTTO_AFTER_OAUTHRESULT);

    const isEdit = this.localStorageService.get<string>(LocalStorageKey.IS_EDIT);
    const path = isEdit === "true" ? "shopifydashboard" : "combipackage/shopify";

    if (window.self === window.top) {
      const queryString = Object.keys(queryParams).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&');
      ShopifyUtil.appRedirect(await this.getShopifyApp(packageInternalKey), `/${path}/${packageInternalKey}?${queryString}`);
    } else if (redirectAfterOAuth?.startsWith("http")) {
      window.top.location.href = redirectAfterOAuth;
    } else {
      await this.router.navigate([`/${path}/${packageInternalKey}`], {queryParams});
    }
  }

  private async getShopifyApp(packageInternalKey: string) {
    const shop = this.route.snapshot.queryParams["shop"];
    const apiKey = this.route.snapshot.queryParams["apioauthkey"] || await this.combiPackageService.getCombiPackageOAuthKeyByInternalKey(packageInternalKey, shop);
    const host = this.route.snapshot.queryParams["host"] || btoa(shop + "/admin");
    return createApp({apiKey, host});
  }
}
