import { Component, Input, OnInit } from '@angular/core';
import { MenuItemIcon } from "./MenuItemIcon";

@Component({
  selector: 'MenuItem',
  imports: [MenuItemIcon],
  template: `
    <div class="group relative">
      <div class="flex items-center">
        <a class="
          font-mark-bold text-lg text-center px-4 py-2 hover:underline center
          lg:text-base
          "
           href="{{ item.href }}"
        >
          @if (item.icon) {
            <MenuItemIcon
                class="mr-2 mt-px"
                [type]="item.icon"
            ></MenuItemIcon>
          }

          {{ item.label }}

          @if (item.children?.length > 0) {
            <svg
                class="hidden lg:block ml-1 translate-y-0.5 flex-none"
                fill="none"
                height="16"
                stroke-width="3"
                stroke="currentColor"
                viewBox="0 0 24 24"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          }
        </a>

        @if (item.children?.length > 0) {
          <div (click)="toggleSubmenu()"
               class="
                  w-8 h-8 center cursor-pointer transition-transform duration-100 select-none translate-y-0.5 -translate-x-4
                  lg:hidden
                  {{ submenuOpen ? 'rotate-0' : '-rotate-90' }}
                  "
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2.5"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </div>
        }
      </div>

      @if (item.children?.length > 0) {
        <div
            class="
        justify-center
        lg:absolute lg:left-1/2 lg:-translate-x-[120px] lg:pt-2
        lg:opacity-0 lg:translate-y-4 transition-all duration-200 lg:pointer-events-none
        lg:group-hover:opacity-100 lg:group-hover:translate-y-0 lg:group-hover:pointer-events-auto
        {{ submenuOpen ? 'flex' : 'hidden lg:flex' }}
       "
        >
          <div
              class="hidden lg:flex absolute left-1/2 top-2 bg-white rotate-45 w-2 h-2 -mt-1"
          ></div>

          <div
              class="
          text-center mb-2 flex flex-col
          lg:bg-white lg:text-gray-800 lg:shadow-lg xl-rounded lg:p-4 lg:pb-6 lg:w-[240px] lg:rounded
          "
          >
            @for (item of item.children; track item.href) {
              <a
                  [href]="item.href"
                  class="p-1.5 hover:underline lg:text-sm lg:hover:no-underline lg:hover:text-blue-500"
              >
                {{ item.label }}
              </a>
            }
          </div>
        </div>
      }
    </div>
  `
})
export class MenuItem implements OnInit {
  @Input() item: {
    href: string;
    label: string;
    icon?: string;
    children?: { label: string; href: string }[];
  } = {
    href: '',
    label: ''
  };

  submenuOpen = false;

  // Open current menu item or parent on mobile
  ngOnInit() {
    const current = window.location.pathname.split('/')[1];
    const slug = this.item.href.split('/')[1];

    if (slug === current) {
      this.submenuOpen = true;
    }
  }

  toggleSubmenu() {
    this.submenuOpen = !this.submenuOpen;
  }
}
