import { Component, Input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Category } from "../model";
import { TranslatePipe } from "@ngx-translate/core";
import { SelectBox } from "./SelectBox";

@Component({
  selector: 'AppFilter',
  imports: [FormsModule, TranslatePipe, SelectBox],
  template: `
    <div
        class="flex flex-col md:flex-row bg-blue-500 shadow-lg shadow-blue-500/50 rounded px-5 py-4 mb-7"
    >
      <input
          class="rounded mb-2.5 md:mb-0 md:mr-5 md:w-2/3 placeholder:text-gray-400 border-0"
          type="text"
          placeholder="{{ 'APPFILTER_SEARCH_PLACEHOLDER' | translate }}"
          [(ngModel)]="query"
          (input)="emitQuery()"
      />

      <SelectBox class="md:flex-grow">
        <select
            class="w-full border-0 bg-none"
            [(ngModel)]="categoryId"
            (change)="emitCategory()"
        >
          <option value="">{{ 'APPFILTER_ALL_CATEGORIES' | translate }}</option>
          @for (cat of categories; track cat.id) {
            <option [value]="cat.id">
              {{ cat.name }}
            </option>
          }
        </select>
      </SelectBox>
    </div>
  `,
})
export class AppFilter {
  updateCategory = output<string>();
  updateQuery = output<string>();

  @Input() categoryId = '';
  @Input() categories: Array<Category>;

  // category = '';
  query = '';

  emitCategory() {
    this.updateCategory.emit(this.categoryId);
  }

  emitQuery() {
    this.updateQuery.emit(this.query);
  }
}
