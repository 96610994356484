import { Component, Input } from '@angular/core';

@Component({
  selector: 'Wrap',
  template: `
    <div class="w-full px-5 md:px-10">
      <div class="w-full mx-auto max-w-[1200px] {{ class }}">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class Wrap {
  @Input() class = '';
}
