import { Component } from '@angular/core';

@Component({
  selector: 'Buttons',
  template: `
    <div class="flex flex-wrap gap-3 md:gap-4 mt-8">
      <ng-content></ng-content>
    </div>
  `,
})
export class Buttons {}
